<template>
  <footer class="bg-purple pb-5 pt-14">
    <div class="gutters grid-layout grid-cols-1 gap-y-8 xl:grid-cols-2">
      <div class="space-y-8 md:space-y-10">
        <p class="type-heading-2 md:type-heading-3">
          Join our newsletter –<br />it’s packed with goodness
        </p>
        <NewsletterSignupForm class="max-w-[440px]" />
      </div>
      <div class="grid-layout type-body-md grid-cols-2 gap-y-8 md:grid-cols-3">
        <div class="nav-link-group">
          <h5>Shop</h5>
          <div>
            <NuxtLink to="/collections/all" class="link">Shop All</NuxtLink>
            <NuxtLink to="/collections/oat-milks" class="link"
              >Oat Milks</NuxtLink
            >
            <div class="inline-flex items-center space-x-2">
              <NuxtLink to="/collections/bundles" class="link"
                >Bundles</NuxtLink
              >
              <ProductSaveBadge />
            </div>
            <NuxtLink to="/collections/accessories" class="link"
              >Accessories</NuxtLink
            >
            <div class="relative">
              <span>&nbsp;</span>
              <div class="absolute left-0 top-1/2 h-px w-8 bg-black/10" />
            </div>
            <NuxtLink
              to="https://wholesale.drinknimbus.co/pages/stockists"
              target="_blank"
              class="link"
              >Stockists</NuxtLink
            >
            <NuxtLink
              to="https://wholesale.drinknimbus.co/"
              target="_blank"
              class="link"
              >Wholesale Store</NuxtLink
            >
          </div>
        </div>
        <div class="nav-link-group">
          <h5>About</h5>
          <div>
            <NuxtLink to="/mission" class="link">Mission</NuxtLink>
            <NuxtLink to="/faqs" class="link">FAQs</NuxtLink>
            <NuxtLink to="/recycling" class="link">Recycling</NuxtLink>
            <NuxtLink to="/delivery-returns" class="link"
              >Delivery & Returns</NuxtLink
            >
            <NuxtLink to="/terms" class="link">Terms</NuxtLink>
            <NuxtLink to="/privacy-policy" class="link"
              >Privacy Policy</NuxtLink
            >
          </div>
        </div>
        <div class="nav-link-group">
          <h5>Follow Us</h5>
          <div>
            <NuxtLink :to="instagramProfile.url" target="_blank" class="link"
              >Instagram</NuxtLink
            >
          </div>
        </div>
      </div>
    </div>
    <div class="gutters space-y-8">
      <div class="flex items-end justify-between">
        <div class="type-body-sm flex flex-col items-start space-y-1">
          <span>Copyright {{ currentYear }}</span>
          <span class="text-black/50"
            >Site by
            <a
              href="https://madetogether.com.au"
              target="_blank"
              class="transition-colors duration-100 hover:text-black"
              >Made Together</a
            ></span
          >
        </div>
        <AnimationFloatingSimple class="w-[124px] xl:w-[160px]" />
      </div>
      <SvgNimbusLogotype registered />
    </div>
  </footer>
</template>

<script lang="ts" setup>
const currentYear = new Date().getFullYear()
const instagramProfile = useInstagramProfile()
</script>

<style lang="postcss" scoped>
.nav-link-group {
  @apply space-y-3;
}

.nav-link-group h5 {
  @apply uppercase;
}

.nav-link-group > div {
  @apply flex flex-col items-start space-y-1;
}
</style>
